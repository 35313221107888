import * as Sentry from '@sentry/sveltekit';
import '@src/lib/client/db';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
  dsn: 'https://3f41c01122e43d8dfa35db144809967c@o4506775983947776.ingest.us.sentry.io/4508320236437504',
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  integrations: [Sentry.replayIntegration()]
});

export const handleError = Sentry.handleErrorWithSentry();
