import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [5],
		"/about/privacy": [6],
		"/admin": [7,[2]],
		"/driver": [~8,[3]],
		"/driver/about": [10,[3]],
		"/driver/instrucciones": [11,[3]],
		"/driver/instructions": [12,[3]],
		"/driver/mode-demploi": [13,[3]],
		"/driver/opt-out": [14,[3]],
		"/driver/opt-out/survey/[slug]": [15,[3]],
		"/driver/settings": [~16,[3]],
		"/driver/settings/[slug]": [17,[3]],
		"/driver/survey/demographics/done": [20,[3]],
		"/driver/survey/demographics/[shortUserId]": [~19,[3]],
		"/driver/survey/done": [21,[3]],
		"/driver/survey/take/[shortUserId]": [~22,[3]],
		"/driver/survey/[shortUserId]": [~18,[3]],
		"/driver/take/[userCode]": [~23,[3]],
		"/driver/[slug]": [9,[3]],
		"/dsc/opt-out": [24],
		"/dsc/privacy": [25],
		"/organizer": [26,[4]],
		"/organizer/login": [27,[4]],
		"/organizer/org/[affiliate_organization_key]": [~28,[4]],
		"/organizer/org/[affiliate_organization_key]/[driver_short_id]": [~29,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';